import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './components/Routes/Router';
 
function App() {
  return (
    <div>
    <Router />
  </div>
  );
}
 
 
 
 
export default App;